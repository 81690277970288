import { getDecodedParamFromQuery } from "utils/url";
import { NUMBER_OF_SEATS_PARAM, SALES_ENRICHMENT_PARAM_LIST, SALES_ENRICHMENT_PARAM_TYPES } from "client/services/hubspot-service/hubspot-constants";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";
export const getSingleEnrichmentEntry = (paramName, hubspotApiName)=>{
    var _localStorage;
    const paramValue = JSON.parse(((_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.getItem(paramName)) || "null");
    if (paramValue) {
        const { value } = paramValue;
        return {
            name: hubspotApiName,
            value
        };
    }
};
export const getEnrichmentEntriesForSales = ()=>{
    try {
        const enrichmentEntries = [];
        for (const item of SALES_ENRICHMENT_PARAM_LIST){
            const { paramName, hubspotApiName } = item;
            const singleEnrichmentEntry = getSingleEnrichmentEntry(paramName, hubspotApiName);
            if (singleEnrichmentEntry) {
                enrichmentEntries.push(singleEnrichmentEntry);
            }
        }
        return enrichmentEntries;
    } catch (e) {
        handleClientException({
            e
        });
        return [];
    }
};
const storeEnrichmentParamInLocalStorage = ({ paramName, paramValue, storeOnlyOnFirstTimeInSession = false })=>{
    var _localStorage, _localStorage1;
    if (storeOnlyOnFirstTimeInSession && ((_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.getItem(paramName))) return;
    (_localStorage1 = localStorage) === null || _localStorage1 === void 0 ? void 0 : _localStorage1.setItem(paramName, JSON.stringify({
        value: paramValue,
        timestamp: Date.now()
    }));
};
const storeUtmDataFromUrl = ()=>{
    const salesEnrichementUrlParamList = SALES_ENRICHMENT_PARAM_LIST.filter((item)=>item.type === SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM);
    for (const item of salesEnrichementUrlParamList){
        const paramName = item.paramName;
        const paramValue = getDecodedParamFromQuery(window.location, paramName);
        if (paramValue) {
            storeEnrichmentParamInLocalStorage({
                paramName,
                paramValue,
                storeOnlyOnFirstTimeInSession: true
            });
        }
    }
};
export const storeNumberOfSeats = (numberOfSeats)=>{
    storeEnrichmentParamInLocalStorage({
        paramName: NUMBER_OF_SEATS_PARAM,
        paramValue: numberOfSeats
    });
};
export const StoreUTMDataForEnrichment = ()=>{
    try {
        storeUtmDataFromUrl();
    } catch (e) {
        handleClientException({
            e
        });
    }
};
