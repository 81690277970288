import styled from "@emotion/styled";
export const StyledYouTubePopUpButton = styled.div`
  .secondary-button-wrapper {
    &:hover {
      outline: 0;
    }
  }
  .play-wrapper {
    padding-right: 16px;
    svg {
      display: block;
      circle {
        stroke: currentColor;
      }
      path {
        fill: currentColor;
      }
    }

    &.regular-button {
      padding-right: 4px;
    }
  }
`;
