import * as FEATURES from "../features/features";
export const getFreeTierEssentials = (abTests, cookies)=>{
    return {
        sectionName: "pricingPage.features.sections.essentials",
        features: [
            FEATURES.MAXIMUM_SEATS,
            FEATURES.ITEMS({
                abTests,
                cookies
            }),
            FEATURES.STORAGE({
                abTests,
                cookies
            }),
            FEATURES.ACTIVITY_LOG,
            FEATURES.UNLIMITED_BOARDS_WITH_FREE_TIER({
                abTests,
                cookies
            }),
            FEATURES.UNLIMITED_DOCS,
            FEATURES.COLUMN_TYPES({
                cookies
            }),
            FEATURES.TEMPLATES,
            FEATURES.MOBILE,
            FEATURES.UNLIMITED_VIEWERS
        ]
    };
};
