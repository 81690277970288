import * as FEATURES from "../features/features";
export const CUSTOMER_FAVORITES = ({ hasStudentPlan, cookies })=>{
    return {
        sectionName: "pricingPage.features.sections.customer_favorites",
        features: [
            FEATURES.UNLIMITED_BOARDS,
            FEATURES.TEMPLATES,
            FEATURES.COLUMN_TYPES({
                cookies
            }),
            FEATURES.UNLIMITED_VIEWERS,
            FEATURES.FORMS,
            FEATURES.MOBILE,
            FEATURES.IMPORT_FROM_OTHER_TOOLS,
            FEATURES.TIMELINE({
                hasStudentPlan
            }),
            FEATURES.PRIVATE_BOARDS,
            FEATURES.AUTOMATIONS
        ]
    };
};
export const COLLABORATION = ({ cookies, abTests, hasFreeTier, hasStudentPlan })=>{
    const featuresList = [
        FEATURES.EMBEDDED_BOARD,
        FEATURES.WHITEBOARD,
        FEATURES.UPDATES_SECTION,
        FEATURES.ZOOM_INTEGRATION,
        FEATURES.GUESTS
    ];
    if (!(hasFreeTier || hasStudentPlan)) {
        // otherwise, the following features already appear via the 'ESSENTIALS' section.
        featuresList.unshift(FEATURES.UNLIMITED_VIEWERS);
        featuresList.push(FEATURES.STORAGE({
            cookies,
            abTests
        }));
    }
    return {
        sectionName: "pricingPage.features.sections.collaboration",
        features: featuresList
    };
};
export const PRODUCTIVITY = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.productivity",
        features: [
            FEATURES.MOBILE,
            FEATURES.FORMS,
            FEATURES.CUSTOMIZABLE_NOTIFICATIONS,
            FEATURES.INTEGRATIONS,
            FEATURES.AUTOMATIONS,
            FEATURES.PREMIUM_INTEGRATIONS,
            FEATURES.TIME_TRACKING,
            FEATURES.FORMULA({
                hasStudentPlan
            }),
            FEATURES.DEPENDENCY_COLUMN
        ]
    };
};
export const VIEWS_AND_REPORTING = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.views_and_reporting",
        features: [
            FEATURES.ACTIVITY_LOG,
            FEATURES.DASHBOARDS({
                hasStudentPlan
            }),
            FEATURES.KANBAN_VIEW,
            FEATURES.TIMELINE({
                hasStudentPlan
            }),
            FEATURES.CALENDAR({
                hasStudentPlan
            }),
            FEATURES.MAP,
            FEATURES.CHART_VIEW({
                hasStudentPlan
            }),
            FEATURES.WORKLOAD
        ]
    };
};
// Newest, winning version.
export const SUPPORT_NEW = {
    sectionName: "pricingPage.features.sections.support",
    features: [
        FEATURES.KNOWLEDGE_BASE,
        FEATURES.EMAIL_SUPPORT,
        FEATURES.WEBINARS,
        FEATURES.CS_MANAGER,
        FEATURES.SLA
    ]
};
// New, winning version.
export const SECURITY_AND_PRIVACY = {
    sectionName: "pricingPage.features.sections.security_and_privacy",
    features: [
        FEATURES.SOC,
        FEATURES.TWO_FA,
        FEATURES.PRIVATE_BOARDS,
        FEATURES.GOOGLE,
        FEATURES.SAML,
        FEATURES.HIPAA,
        FEATURES.INTEGRATION_PERMISSIONS,
        FEATURES.IP_RESTRICTIONS,
        FEATURES.CONTENT_DIRECTORY,
        FEATURES.TEAMS_INSIDE_TEAMS,
        FEATURES.CUSTOM_ROLES,
        FEATURES.SEATS_MANAGEMENT,
        FEATURES.TENANT_RESTRICTIONS,
        FEATURES.APPROVED_GUEST,
        FEATURES.BLOCK_DOMAINS
    ]
};
export const ADMINISTRATION_AND_CONTROL = {
    sectionName: "pricingPage.features.sections.administration_and_control",
    features: [
        FEATURES.MAXIMUM_SEATS,
        FEATURES.BOARD_ADMINISTRATORS,
        FEATURES.SCIM,
        FEATURES.AUDIT_LOG,
        FEATURES.SESSIONS,
        FEATURES.PANIC_MODE,
        FEATURES.PRIVATE_WORKSPACES,
        FEATURES.ACCOUNT_PERMISSIONS
    ]
};
export const ENTERPRISE_REPORTING_AND_ANALYTICS = {
    sectionName: "pricingPage.features.sections.enterprise_reporting_and_analytics",
    features: [
        FEATURES.WORK_PERFORMANCE_INSIGHTS,
        FEATURES.SCHEDULED_DASHBOARD_DELIVERY,
        FEATURES.PIVOT_ANALYSIS
    ]
};
export const NEW_PRICING_PAGE_DEFAULT_SECTIONS = ({ cookies, abTests, hasStudentPlan, hasFreeTier })=>[
        CUSTOMER_FAVORITES({
            hasStudentPlan,
            cookies
        }),
        COLLABORATION({
            cookies,
            abTests,
            hasStudentPlan,
            hasFreeTier
        }),
        PRODUCTIVITY({
            hasStudentPlan
        }),
        VIEWS_AND_REPORTING({
            hasStudentPlan
        }),
        SUPPORT_NEW,
        SECURITY_AND_PRIVACY,
        ADMINISTRATION_AND_CONTROL,
        ENTERPRISE_REPORTING_AND_ANALYTICS
    ];
export const REMOTE_WORK_FEATURES_SECTION = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.remote_work",
        features: [
            FEATURES.WHITEBOARD,
            FEATURES.EMBEDDED_BOARD,
            FEATURES.ZOOM_INTEGRATION,
            FEATURES.TIMELINE({
                hasStudentPlan
            }),
            FEATURES.CALENDAR({
                hasStudentPlan
            })
        ]
    };
};
export const ADVANCED = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.advanced",
        features: [
            FEATURES.GUESTS,
            FEATURES.KANBAN_VIEW,
            FEATURES.FORMS,
            FEATURES.MAP,
            FEATURES.SEARCH,
            FEATURES.FORMS_CUSTOMIZATION({
                hasStudentPlan
            }),
            FEATURES.WORKLOAD,
            FEATURES.TIME_TRACKING,
            FEATURES.CHART_VIEW({
                hasStudentPlan
            }),
            FEATURES.FORMULA({
                hasStudentPlan
            }),
            FEATURES.DEPENDENCY_COLUMN,
            FEATURES.TAGS,
            FEATURES.PRIVATE_BOARDS,
            FEATURES.INTEGRATIONS,
            FEATURES.AUTOMATIONS,
            FEATURES.PREMIUM_INTEGRATIONS,
            FEATURES.DASHBOARDS({
                hasStudentPlan
            })
        ]
    };
};
// Old version, before any tests. Kept here until we're 100% sure none of the clusters use it.
export const SECURITY = {
    sectionName: "pricingPage.features.sections.security",
    features: [
        FEATURES.TWO_FA,
        FEATURES.GOOGLE,
        FEATURES.SAML,
        FEATURES.SCIM,
        FEATURES.AUDIT_LOG,
        FEATURES.SESSIONS,
        FEATURES.HIPAA,
        FEATURES.SLA,
        FEATURES.INTEGRATION_PERMISSIONS,
        FEATURES.PRIVATE_WORKSPACES,
        FEATURES.ACCOUNT_PERMISSIONS
    ]
};
export const ADVANCED_REPORTING = {
    sectionName: "pricingPage.features.sections.advancedReporting",
    features: [
        FEATURES.WORK_PERFORMANCE_INSIGHTS,
        FEATURES.SCHEDULED_DASHBOARD_DELIVERY,
        FEATURES.PIVOT_ANALYSIS
    ]
};
// Old version, kept until we're certain none of the clusters use it.
export const SUPPORT = {
    sectionName: "pricingPage.features.sections.support",
    features: [
        FEATURES.KNOWLEDGE_BASE,
        FEATURES.EMAIL_SUPPORT,
        FEATURES.TAILORED_ONBOARDING
    ]
};
// Newer version, of a successful test.
export const SECURITY_NEW = {
    sectionName: "pricingPage.features.sections.security_new",
    features: [
        FEATURES.TWO_FA,
        FEATURES.SOC,
        FEATURES.GOOGLE,
        FEATURES.SAML,
        FEATURES.HIPAA,
        FEATURES.INTEGRATION_PERMISSIONS,
        FEATURES.IP_RESTRICTIONS,
        FEATURES.CONTENT_DIRECTORY
    ]
};
export const getDefaultSections = ({ cookies, abTests, hasStudentPlan, hasFreeTier })=>{
    return NEW_PRICING_PAGE_DEFAULT_SECTIONS({
        cookies,
        abTests,
        hasStudentPlan,
        hasFreeTier
    });
};
