export default {
    buttonText: "Button",
    isWithArrow: true,
    color: "black",
    isWithIcon: false,
    iconColor: "green",
    isWithIconInsteadOfArrow: false,
    outline: false,
    minPadding: false
};
