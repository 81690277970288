import { VERSION_13, VERSION_12, VERSION_11, VERSION_10, VERSION_9 } from "constants/pricing-versions";
const free = {
    usd: 0,
    euro: 0,
    gbp: 0,
    cad: 0,
    aud: 0,
    brl: 0,
    mxn: 0,
    inr: 0,
    jpy: 0
};
export const productPrices = {
    core: {
        [VERSION_9]: {
            monthly: {
                free,
                basic: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 43,
                    mxn: 200,
                    inr: 750,
                    jpy: 1100
                },
                standard: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 55,
                    mxn: 240,
                    inr: 900,
                    jpy: 1400
                },
                pro: {
                    usd: 20,
                    euro: 20,
                    gbp: 18,
                    cad: 27,
                    aud: 27,
                    brl: 91,
                    mxn: 400,
                    inr: 1500,
                    jpy: 2200
                },
                enterprise: {
                    usd: 46,
                    euro: 46,
                    gbp: 40,
                    cad: 62,
                    aud: 62,
                    brl: 217,
                    mxn: 920,
                    inr: 3450,
                    jpy: 4700
                }
            },
            yearly: {
                free,
                basic: {
                    usd: 8,
                    euro: 8,
                    gbp: 7,
                    cad: 11,
                    aud: 11,
                    brl: 35,
                    mxn: 160,
                    inr: 600,
                    jpy: 900
                },
                standard: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 45,
                    mxn: 200,
                    inr: 750,
                    jpy: 1100
                },
                pro: {
                    usd: 16,
                    euro: 16,
                    gbp: 14,
                    cad: 22,
                    aud: 22,
                    brl: 75,
                    mxn: 320,
                    inr: 1200,
                    jpy: 1800
                },
                enterprise: {
                    usd: 38,
                    euro: 38,
                    gbp: 33,
                    cad: 51,
                    aud: 51,
                    brl: 178,
                    mxn: 760,
                    inr: 2850,
                    jpy: 4200
                }
            }
        },
        [VERSION_10]: {
            monthly: {
                free,
                basic: {
                    usd: 8,
                    euro: 8,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 37,
                    mxn: 150,
                    inr: 550,
                    jpy: 850
                },
                standard: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 60,
                    mxn: 240,
                    inr: 900,
                    jpy: 1400
                },
                pro: {
                    usd: 27,
                    euro: 27,
                    gbp: 23,
                    cad: 37,
                    aud: 37,
                    brl: 135,
                    mxn: 540,
                    inr: 2000,
                    jpy: 3000
                },
                enterprise: {
                    usd: 54,
                    euro: 54,
                    gbp: 46,
                    cad: 74,
                    aud: 74,
                    brl: 270,
                    mxn: 1100,
                    inr: 4025,
                    jpy: 6100
                }
            },
            yearly: {
                free,
                basic: {
                    usd: 6,
                    euro: 6,
                    gbp: 5,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 700
                },
                standard: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 50,
                    mxn: 200,
                    inr: 750,
                    jpy: 1100
                },
                pro: {
                    usd: 22,
                    euro: 22,
                    gbp: 19,
                    cad: 30,
                    aud: 30,
                    brl: 110,
                    mxn: 440,
                    inr: 1650,
                    jpy: 2500
                },
                enterprise: {
                    usd: 44,
                    euro: 44,
                    gbp: 38,
                    cad: 60,
                    aud: 60,
                    brl: 220,
                    mxn: 900,
                    inr: 3300,
                    jpy: 5000
                }
            }
        },
        [VERSION_11]: {
            monthly: {
                free,
                basic: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 43,
                    mxn: 200,
                    inr: 750,
                    jpy: 1300
                },
                standard: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 55,
                    mxn: 240,
                    inr: 900,
                    jpy: 1700
                },
                pro: {
                    usd: 20,
                    euro: 20,
                    gbp: 18,
                    cad: 27,
                    aud: 27,
                    brl: 91,
                    mxn: 400,
                    inr: 1500,
                    jpy: 3300
                },
                enterprise: {
                    usd: 46,
                    euro: 46,
                    gbp: 40,
                    cad: 62,
                    aud: 62,
                    brl: 217,
                    mxn: 920,
                    inr: 3450,
                    jpy: 4700
                }
            },
            yearly: {
                free,
                basic: {
                    usd: 8,
                    euro: 8,
                    gbp: 7,
                    cad: 11,
                    aud: 11,
                    brl: 35,
                    mxn: 160,
                    inr: 600,
                    jpy: 1100
                },
                standard: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 45,
                    mxn: 200,
                    inr: 750,
                    jpy: 1400
                },
                pro: {
                    usd: 16,
                    euro: 16,
                    gbp: 14,
                    cad: 22,
                    aud: 22,
                    brl: 75,
                    mxn: 320,
                    inr: 1200,
                    jpy: 2700
                },
                enterprise: {
                    usd: 38,
                    euro: 38,
                    gbp: 33,
                    cad: 51,
                    aud: 51,
                    brl: 178,
                    mxn: 760,
                    inr: 2850,
                    jpy: 4200
                }
            }
        },
        [VERSION_12]: {
            monthly: {
                free,
                basic: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 43,
                    mxn: 200,
                    inr: 750,
                    jpy: 1300
                },
                standard: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 55,
                    mxn: 240,
                    inr: 900,
                    jpy: 1700
                },
                pro: {
                    usd: 24,
                    euro: 24,
                    gbp: 21,
                    cad: 32,
                    aud: 32,
                    brl: 108,
                    mxn: 475,
                    inr: 1780,
                    jpy: 3300
                },
                enterprise: {
                    usd: 54,
                    euro: 54,
                    gbp: 46,
                    cad: 74,
                    aud: 74,
                    brl: 270,
                    mxn: 1100,
                    inr: 4025,
                    jpy: 6100
                }
            },
            yearly: {
                free,
                basic: {
                    usd: 8,
                    euro: 8,
                    gbp: 7,
                    cad: 11,
                    aud: 11,
                    brl: 35,
                    mxn: 160,
                    inr: 600,
                    jpy: 1100
                },
                standard: {
                    usd: 10,
                    euro: 10,
                    gbp: 9,
                    cad: 14,
                    aud: 14,
                    brl: 45,
                    mxn: 200,
                    inr: 750,
                    jpy: 1400
                },
                pro: {
                    usd: 19,
                    euro: 19,
                    gbp: 17,
                    cad: 26,
                    aud: 26,
                    brl: 89,
                    mxn: 380,
                    inr: 1425,
                    jpy: 2700
                },
                enterprise: {
                    usd: 44,
                    euro: 44,
                    gbp: 38,
                    cad: 60,
                    aud: 60,
                    brl: 220,
                    mxn: 900,
                    inr: 3300,
                    jpy: 5000
                }
            }
        },
        [VERSION_13]: {
            monthly: {
                free,
                basic: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 49,
                    mxn: 240,
                    inr: 890,
                    jpy: 1530
                },
                standard: {
                    usd: 14,
                    euro: 14,
                    gbp: 13,
                    cad: 20,
                    aud: 20,
                    brl: 65,
                    mxn: 280,
                    inr: 1060,
                    jpy: 1990
                },
                pro: {
                    usd: 24,
                    euro: 24,
                    gbp: 21,
                    cad: 32,
                    aud: 32,
                    brl: 105,
                    mxn: 480,
                    inr: 1770,
                    jpy: 3890
                },
                enterprise: {
                    usd: 54,
                    euro: 54,
                    gbp: 47,
                    cad: 73,
                    aud: 73,
                    brl: 255,
                    mxn: 1080,
                    inr: 4070,
                    jpy: 8700
                }
            },
            yearly: {
                free,
                basic: {
                    usd: 9,
                    euro: 9,
                    gbp: 8,
                    cad: 13,
                    aud: 13,
                    brl: 41,
                    mxn: 190,
                    inr: 600,
                    jpy: 1300
                },
                standard: {
                    usd: 12,
                    euro: 12,
                    gbp: 11,
                    cad: 17,
                    aud: 17,
                    brl: 53,
                    mxn: 240,
                    inr: 800,
                    jpy: 1650
                },
                pro: {
                    usd: 19,
                    euro: 19,
                    gbp: 17,
                    cad: 26,
                    aud: 26,
                    brl: 89,
                    mxn: 380,
                    inr: 1250,
                    jpy: 3200
                },
                enterprise: {
                    usd: 44,
                    euro: 44,
                    gbp: 39,
                    cad: 60,
                    aud: 60,
                    brl: 210,
                    mxn: 900,
                    inr: 3400,
                    jpy: 7200
                }
            }
        }
    },
    marketing: {
        [VERSION_9]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 7.5,
                    euro: 7.5,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 36,
                    mxn: 150,
                    inr: 562.5,
                    jpy: 845
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 3,
                    euro: 3,
                    gbp: 2.3,
                    cad: 4,
                    aud: 4,
                    brl: 15,
                    mxn: 60,
                    inr: 225,
                    jpy: 350
                },
                pro: {
                    usd: 6,
                    euro: 6,
                    gbp: 4.6,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 680
                },
                enterprise: free
            }
        },
        [VERSION_10]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 7.5,
                    euro: 7.5,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 36,
                    mxn: 150,
                    inr: 562.5,
                    jpy: 845
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 3,
                    euro: 3,
                    gbp: 2.3,
                    cad: 4,
                    aud: 4,
                    brl: 15,
                    mxn: 60,
                    inr: 225,
                    jpy: 350
                },
                pro: {
                    usd: 6,
                    euro: 6,
                    gbp: 4.6,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 680
                },
                enterprise: free
            }
        },
        [VERSION_11]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 7.5,
                    euro: 7.5,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 36,
                    mxn: 150,
                    inr: 562.5,
                    jpy: 845
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 3,
                    euro: 3,
                    gbp: 2.3,
                    cad: 4,
                    aud: 4,
                    brl: 15,
                    mxn: 60,
                    inr: 225,
                    jpy: 350
                },
                pro: {
                    usd: 6,
                    euro: 6,
                    gbp: 4.6,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 680
                },
                enterprise: free
            }
        },
        [VERSION_12]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 7.5,
                    euro: 7.5,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 36,
                    mxn: 150,
                    inr: 562.5,
                    jpy: 845
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 3,
                    euro: 3,
                    gbp: 2.3,
                    cad: 4,
                    aud: 4,
                    brl: 15,
                    mxn: 60,
                    inr: 225,
                    jpy: 350
                },
                pro: {
                    usd: 6,
                    euro: 6,
                    gbp: 4.6,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 680
                },
                enterprise: free
            }
        },
        [VERSION_13]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 7.5,
                    euro: 7.5,
                    gbp: 6,
                    cad: 10,
                    aud: 10,
                    brl: 36,
                    mxn: 150,
                    inr: 562.5,
                    jpy: 845
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 3,
                    euro: 3,
                    gbp: 2.3,
                    cad: 4,
                    aud: 4,
                    brl: 15,
                    mxn: 60,
                    inr: 225,
                    jpy: 350
                },
                pro: {
                    usd: 6,
                    euro: 6,
                    gbp: 4.6,
                    cad: 8,
                    aud: 8,
                    brl: 30,
                    mxn: 120,
                    inr: 450,
                    jpy: 680
                },
                enterprise: free
            }
        }
    },
    crm: {
        [VERSION_9]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                pro: {
                    usd: 10,
                    euro: 10,
                    gbp: 8,
                    cad: 13.2,
                    aud: 13.2,
                    brl: 48,
                    mxn: 200,
                    inr: 750,
                    jpy: 1125
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 8,
                    euro: 8,
                    gbp: 6,
                    cad: 10.6,
                    aud: 10.6,
                    brl: 40,
                    mxn: 160,
                    inr: 600,
                    jpy: 900
                },
                enterprise: free
            }
        },
        [VERSION_10]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                pro: {
                    usd: 10,
                    euro: 10,
                    gbp: 8,
                    cad: 13.2,
                    aud: 13.2,
                    brl: 48,
                    mxn: 200,
                    inr: 750,
                    jpy: 1125
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 8,
                    euro: 8,
                    gbp: 6,
                    cad: 10.6,
                    aud: 10.6,
                    brl: 40,
                    mxn: 160,
                    inr: 600,
                    jpy: 900
                },
                enterprise: free
            }
        },
        [VERSION_11]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                pro: {
                    usd: 10,
                    euro: 10,
                    gbp: 8,
                    cad: 13.2,
                    aud: 13.2,
                    brl: 48,
                    mxn: 200,
                    inr: 750,
                    jpy: 1125
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 8,
                    euro: 8,
                    gbp: 6,
                    cad: 10.6,
                    aud: 10.6,
                    brl: 40,
                    mxn: 160,
                    inr: 600,
                    jpy: 900
                },
                enterprise: free
            }
        },
        [VERSION_12]: {
            monthly: {
                free,
                basic: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                standard: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                pro: {
                    usd: 10,
                    euro: 10,
                    gbp: 8,
                    cad: 13.2,
                    aud: 13.2,
                    brl: 48,
                    mxn: 200,
                    inr: 750,
                    jpy: 1125
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                pro: {
                    usd: 8,
                    euro: 8,
                    gbp: 6,
                    cad: 10.6,
                    aud: 10.6,
                    brl: 40,
                    mxn: 160,
                    inr: 600,
                    jpy: 900
                },
                enterprise: free
            }
        },
        [VERSION_13]: {
            monthly: {
                free,
                basic: {
                    usd: 3,
                    euro: 3,
                    gbp: 2,
                    cad: 4,
                    aud: 4,
                    brl: 16,
                    mxn: 60,
                    inr: 210,
                    jpy: 330
                },
                standard: {
                    usd: 6,
                    euro: 6,
                    gbp: 5,
                    cad: 8,
                    aud: 8,
                    brl: 29,
                    mxn: 120,
                    inr: 440,
                    jpy: 680
                },
                pro: {
                    usd: 9,
                    euro: 9,
                    gbp: 10,
                    cad: 16,
                    aud: 16,
                    brl: 59,
                    mxn: 220,
                    inr: 880,
                    jpy: 1330
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 3,
                    euro: 3,
                    gbp: 2,
                    cad: 5,
                    aud: 3,
                    brl: 12,
                    mxn: 40,
                    inr: 200,
                    jpy: 260
                },
                standard: {
                    usd: 5,
                    euro: 5,
                    gbp: 3,
                    cad: 6,
                    aud: 6,
                    brl: 24,
                    mxn: 90,
                    inr: 350,
                    jpy: 530
                },
                pro: {
                    usd: 9,
                    euro: 9,
                    gbp: 7,
                    cad: 13,
                    aud: 13,
                    brl: 46,
                    mxn: 180,
                    inr: 600,
                    jpy: 1050
                },
                enterprise: free
            }
        }
    },
    projectManagement: {
        [VERSION_9]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_10]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_11]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_12]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_13]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        }
    },
    software: {
        [VERSION_9]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_10]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_11]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_12]: {
            monthly: {
                free,
                basic: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                standard: {
                    usd: 2.5,
                    euro: 2.5,
                    gbp: 2,
                    cad: 3.3,
                    aud: 3.3,
                    brl: 12,
                    mxn: 50,
                    inr: 187.5,
                    jpy: 280
                },
                pro: {
                    usd: 5,
                    euro: 5,
                    gbp: 4,
                    cad: 6.6,
                    aud: 6.6,
                    brl: 24,
                    mxn: 100,
                    inr: 375,
                    jpy: 560
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 1,
                    euro: 1,
                    gbp: 0.75,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 5,
                    mxn: 20,
                    inr: 75,
                    jpy: 115
                },
                standard: {
                    usd: 2,
                    euro: 2,
                    gbp: 1.5,
                    cad: 2.65,
                    aud: 2.65,
                    brl: 10,
                    mxn: 40,
                    inr: 150,
                    jpy: 225
                },
                pro: {
                    usd: 4,
                    euro: 4,
                    gbp: 3,
                    cad: 5.3,
                    aud: 5.3,
                    brl: 20,
                    mxn: 80,
                    inr: 300,
                    jpy: 450
                },
                enterprise: free
            }
        },
        [VERSION_13]: {
            monthly: {
                free,
                basic: {
                    usd: 0,
                    euro: 0,
                    gbp: -0.5,
                    cad: -0.3,
                    aud: -0.3,
                    brl: 4,
                    mxn: 0,
                    inr: 10,
                    jpy: -5
                },
                standard: {
                    usd: 0.5,
                    euro: 0.5,
                    gbp: 0,
                    cad: 0.3,
                    aud: 0.3,
                    brl: 2,
                    mxn: 10,
                    inr: 28,
                    jpy: -10
                },
                pro: {
                    usd: 1,
                    euro: 1,
                    gbp: 1,
                    cad: 1.6,
                    aud: 1.6,
                    brl: 10,
                    mxn: 20,
                    inr: 105,
                    jpy: -30
                },
                enterprise: free
            },
            yearly: {
                free,
                basic: {
                    usd: 0,
                    euro: 0,
                    gbp: -0.2,
                    cad: -0.7,
                    aud: -0.7,
                    brl: -1,
                    mxn: -10,
                    inr: 0,
                    jpy: -85
                },
                standard: {
                    usd: 0,
                    euro: 0,
                    gbp: -0.5,
                    cad: -0.3,
                    aud: -0.3,
                    brl: 2,
                    mxn: 0,
                    inr: 0,
                    jpy: -25
                },
                pro: {
                    usd: 1,
                    euro: 1,
                    gbp: 0,
                    cad: 1.3,
                    aud: 1.3,
                    brl: 6,
                    mxn: 20,
                    inr: 100,
                    jpy: -50
                },
                enterprise: free
            }
        }
    },
    service: {
        [VERSION_13]: {
            monthly: {
                standard: {
                    usd: 15,
                    euro: 15,
                    gbp: 12,
                    cad: 19,
                    aud: 19,
                    brl: 61,
                    mxn: 300,
                    inr: 1115,
                    jpy: 1997
                },
                pro: {
                    usd: 20,
                    euro: 20,
                    gbp: 17,
                    cad: 27,
                    aud: 27,
                    brl: 87,
                    mxn: 400,
                    inr: 1530,
                    jpy: 2160
                },
                enterprise: free
            },
            yearly: {
                standard: {
                    usd: 14,
                    euro: 14,
                    gbp: 12,
                    cad: 18,
                    aud: 18,
                    brl: 60,
                    mxn: 280,
                    inr: 950,
                    jpy: 1925
                },
                pro: {
                    usd: 19,
                    euro: 19,
                    gbp: 16,
                    cad: 26,
                    aud: 26,
                    brl: 77,
                    mxn: 380,
                    inr: 1250,
                    jpy: 2025
                },
                enterprise: free
            }
        }
    }
};
