import styled from "@emotion/styled";
export const StyledPriceWithCurrency = styled.span`
  display: flex;
  align-items: flex-end;

  .currency-sign-and-text {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2%;
    margin-bottom: 3%;

    .currency-sign-with-text {
      font-size: 0.65em;
    }

    .currency-text {
      font-size: 0.2em;
      font-weight: bold;
    }
  }
`;
