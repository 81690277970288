function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import compact from "lodash/compact";
import isString from "lodash/isString";
import { TIERS } from "constants/pricing";
import { DEFAULT_VALUE_FEATURES_PER_TIER } from "constants/pricing/pricing-page/value-features";
import { PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED } from "constants/bigbrain-event-types";
import * as VALUE_FEATURES from "constants/pricing/pricing-page/features/value_features";
import { trackEvent } from "services/bigbrain-service";
import { isObject, runForEachTier, applyOverrides } from "./clusterized-pricing-page-helper-service";
const isValueFeatureValid = (valueFeature)=>{
    return isString(valueFeature === null || valueFeature === void 0 ? void 0 : valueFeature.name) && isString(valueFeature === null || valueFeature === void 0 ? void 0 : valueFeature.text) && valueFeature.name.length > 0 && valueFeature.text.length > 0;
};
const getExistingValueFeature = (valueFeatureName)=>{
    if (isValueFeatureValid(VALUE_FEATURES[valueFeatureName])) {
        return _object_spread({}, VALUE_FEATURES[valueFeatureName]);
    }
    return null;
};
const getValueFeatureOrNull = (valueFeatureNameOrObject)=>{
    if (isString(valueFeatureNameOrObject)) {
        // shortcut - if a string is provided and it matches an existing code value feature, it will be used.
        const valueFeature = getExistingValueFeature(valueFeatureNameOrObject);
        if (!valueFeature) {
            // missing value feature - emitting event
            trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
                placement: "value_features",
                kind: "name",
                data: valueFeatureNameOrObject
            });
        }
        return valueFeature; // will be null if no such value feature exists.
    } else if (isObject(valueFeatureNameOrObject)) {
        const { feature, name, overrides } = valueFeatureNameOrObject; // 'name' is there for backwards compatibility - TODO remove
        const existingFeature = getExistingValueFeature(feature) || getExistingValueFeature(name) || {};
        const overrideKeys = [
            "name",
            "text",
            "link"
        ];
        const resultingFeature = applyOverrides(existingFeature, overrides, overrideKeys);
        if (!isValueFeatureValid(resultingFeature)) {
            // no source for the value feature's name and text (either a code-based existing feature or config-delivered) -
            // returning null.
            trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
                placement: "value_features",
                kind: "object",
                data: valueFeatureNameOrObject
            });
            return null;
        }
        return resultingFeature;
    }
    // value must be a string or an object.
    trackEvent(PRICING_PAGE_MISSING_CLUSTER_INFO_ENCOUNTERED, {
        placement: "value_features",
        kind: "invalid_type",
        data: valueFeatureNameOrObject
    });
    return null;
};
export const getClusterizedValueFeatures = (clusterValueFeatures, { hasStudentPlan, hasFreeTier, abTests, cookies, forcePricing })=>{
    const valueFeaturesObject = _object_spread({}, DEFAULT_VALUE_FEATURES_PER_TIER({
        hasStudentPlan,
        hasFreeTier,
        abTests,
        cookies,
        forcePricing
    }));
    // overriding default values (using existing value features) on a per-tier basis
    runForEachTier((tier)=>{
        if (tier === TIERS.FREE) return;
        // the Individual plan top features aren't shown at the moment, since it's a reduced, controlled plan.
        const tierClusterFeatures = clusterValueFeatures[tier];
        if (Array.isArray(tierClusterFeatures) && tierClusterFeatures.length > 0) {
            valueFeaturesObject[tier] = compact(tierClusterFeatures.map(getValueFeatureOrNull));
        }
    });
    return valueFeaturesObject;
};
