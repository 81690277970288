function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import isString from "lodash/isString";
import { TIERS } from "constants/pricing";
import englishTranslations from "services/localization/keys/all.json";
export const isObject = (value)=>typeof value === "object" && value !== null;
export const runForEachTier = (callback)=>{
    Object.keys(TIERS).forEach((tierKey)=>callback(TIERS[tierKey]));
};
export const EMPTY_ARRAY = []; // used in multiple places.
export const getFirstTranslatedString = (stringsArray)=>{
    if (!stringsArray || stringsArray.length === 0) return "";
    for (let key of stringsArray){
        if (englishTranslations[key]) {
            return key; // will be translated by the component.
        }
    }
    // couldn't find a translation for this value - treating it as a regular string, possibly enclosed in an array.
    if (Array.isArray(stringsArray)) {
        return `${stringsArray[0]}`;
    }
    return `${stringsArray}`;
};
export const applyOverrides = (originalObject, overridesObject, keys)=>{
    const returnedObject = _object_spread({}, originalObject);
    for (let key of keys){
        const overrideValue = overridesObject === null || overridesObject === void 0 ? void 0 : overridesObject[key];
        if (isString(overrideValue) || Array.isArray(overrideValue)) {
            returnedObject[key] = getFirstTranslatedString(overridesObject[key]);
        }
    }
    return returnedObject;
};
