import { MD } from "components/core/new-button/button-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { BELOW } from "constants/button_additional_text_position";
export default {
    buttonSize: MD,
    buttonText: "Button",
    isOpenLinkInNewTab: false,
    onClick: null,
    color: WORK_OS_IRIS_COLOR_NAME,
    isWithArrow: true,
    minPadding: false,
    additionalText: null,
    additionalTextPosition: BELOW,
    additionalTextMaxWidth: null,
    iconRenderer: null,
    extraEventData: {}
};
