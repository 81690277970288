import styled from "@emotion/styled";
import { ARROW_TRANSITION, UNDERLINE_TRANSITION } from "segments/desktop/core-components/button/button-consts.js";
export const StyledSecondaryButton = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    &.with-underline {
      background: linear-gradient(to right, var(--underline-color-with-opacity), var(--underline-color-with-opacity)),
        linear-gradient(to right, var(--underline-color), var(--underline-color), var(--underline-color));
      background-size: 100% 1px, 0 1px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: ${UNDERLINE_TRANSITION};
      &.with-icon {
        background-size: calc(100% - 48px) 1px, 0px 1px;
        background-position: 100% 100%, 48px 100%;
      }
      &.with-icon-instead-of-arrow {
        background-size: 100% 1px, 0px 1px;
        background-position: 100% 100%, 0px 100%;
      }
      &:hover,
      &:focus {
        background-size: 0 1px, 100% 1px;
      }
    }
  }

  .left-side-icon {
    margin-right: 16px;
  }
  .arrow-wrapper {
    padding-left: 8px;
    padding-right: 4px;
    margin-right: -4px;
    transition: ${ARROW_TRANSITION};
    svg {
      fill: currentColor;
      height: 10px;
      width: 12px;
    }
  }

  .icon-instead-of-arrow-wrapper {
    display: flex;
    padding-left: 8px;
    .play-wrapper {
      padding-right: 0;
      svg {
        width: 16px;
      }
    }
  }

  &:hover {
    .arrow-wrapper {
      padding-left: 12px;
      padding-right: 0px;
    }
  }
`;
