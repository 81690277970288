import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH_M } from "/constants/vp-sizes";
export const StyledSignupComboButtonsButton = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  &.swap-buttons-order {
    flex-direction: row-reverse;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    &.swap-buttons-order {
      flex-direction: column;
    }
  }
`;
