import { ORDERED_PERIOD_NAMES, PERIODS } from "constants/pricing";
import isEmpty from "lodash/isEmpty";
import { isInClusterTest } from "./pricing-page/clusterized-pricing-page-service";
const getClusterPeriodsConfig = (clusterConfig, abTests, cookies, forcePricing)=>{
    var _clusterConfig_payments_view_pricing, _clusterConfig_payments_view;
    const periods = clusterConfig === null || clusterConfig === void 0 ? void 0 : (_clusterConfig_payments_view = clusterConfig.payments_view) === null || _clusterConfig_payments_view === void 0 ? void 0 : (_clusterConfig_payments_view_pricing = _clusterConfig_payments_view.pricing) === null || _clusterConfig_payments_view_pricing === void 0 ? void 0 : _clusterConfig_payments_view_pricing.periods;
    if (!isEmpty(periods) && isInClusterTest(clusterConfig, {
        abTests,
        cookies,
        forcePricing
    })) {
        return periods;
    }
    return {};
};
export const getPeriods = (clusterConfig, abTests, cookies, forcePricing)=>{
    const periods = ORDERED_PERIOD_NAMES;
    const clusterPeriodsConfig = getClusterPeriodsConfig(clusterConfig, abTests, cookies, forcePricing);
    const { hidden_periods: hiddenPeriods = [] } = clusterPeriodsConfig || {};
    return isEmpty(hiddenPeriods) ? periods : periods.filter((period)=>!hiddenPeriods.includes(period));
};
export const shouldShowYearlyPrices = (clusterConfig, abTests, cookies, forcePricing)=>{
    const clusterPeriodsConfig = getClusterPeriodsConfig(clusterConfig, abTests, cookies, forcePricing);
    const { display } = clusterPeriodsConfig;
    return (display === null || display === void 0 ? void 0 : display.price_display) === PERIODS.yearly;
};
