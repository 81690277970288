// Define constants for each company size
export const SMALLEST_COMPANY_SIZE = "1-19";
export const COMPANY_SIZE_20_49 = "20-49";
export const COMPANY_SIZE_50_99 = "50-99";
export const COMPANY_SIZE_100_250 = "100-250";
export const COMPANY_SIZE_251_1500 = "251-1500";
export const COMPANY_SIZE_1500_PLUS = "1500+";
// Use the constants in the data structures
export const companySizeList = [
    SMALLEST_COMPANY_SIZE,
    COMPANY_SIZE_20_49,
    COMPANY_SIZE_50_99,
    COMPANY_SIZE_100_250,
    COMPANY_SIZE_251_1500,
    COMPANY_SIZE_1500_PLUS
];
export const companySizeValuesList = [
    {
        label: SMALLEST_COMPANY_SIZE,
        value: SMALLEST_COMPANY_SIZE
    },
    {
        label: COMPANY_SIZE_20_49,
        value: COMPANY_SIZE_20_49
    },
    {
        label: COMPANY_SIZE_50_99,
        value: COMPANY_SIZE_50_99
    },
    {
        label: COMPANY_SIZE_100_250,
        value: COMPANY_SIZE_100_250
    },
    {
        label: COMPANY_SIZE_251_1500,
        value: COMPANY_SIZE_251_1500
    },
    {
        label: COMPANY_SIZE_1500_PLUS,
        value: COMPANY_SIZE_1500_PLUS
    }
];
