import { companySizeList } from "./form-constants";
import { EMAIL_PATTERN } from "constants/input-validations-patterns";
import TextInput from "client/components/system/form-inputs/text-input/text-input";
import TextareaInput from "client/components/system/form-inputs/textarea-input/textarea-input";
import PhoneInput from "client/components/system/form-inputs/phone-input/phone-input";
import SelectInput from "client/components/system/form-inputs/select-input/select-input";
import CheckboxInput from "client/components/system/form-inputs/checkbox-input/checkbox-input";
import MultiSelectInput from "client/components/system/form-inputs/multi-select-input/multi-select-input";
export const inputTypes = {
    TEXT: "text",
    NUMBER: "number",
    EMAIL: "email",
    SELECT: "select",
    TEXT_AREA: "text_area",
    PHONE: "phone",
    CHECKBOX: "checkbox",
    MULTI_SELECT: "multi_select"
};
const inputTypesToComponent = {
    [inputTypes.TEXT]: TextInput,
    [inputTypes.NUMBER]: TextInput,
    [inputTypes.EMAIL]: TextInput,
    [inputTypes.SELECT]: SelectInput,
    [inputTypes.MULTI_SELECT]: MultiSelectInput,
    [inputTypes.TEXT_AREA]: TextareaInput,
    [inputTypes.PHONE]: PhoneInput,
    [inputTypes.CHECKBOX]: CheckboxInput
};
const MAXIMUM_INPUT_LENGTH = 40;
const MINIMUM_PHONE_LENGTH = 8;
const MAXIMUM_PHONE_LENGTH = 40;
export const MAXIMUM_LONG_INPUT_LENGTH = 120;
export const getInputComponentByType = (type)=>inputTypesToComponent[type] || TextInput;
export const FIRSTNAME_INPUT = {
    id: "first_name",
    type: inputTypes.TEXT,
    title: "forms.fieldTitle.firstName",
    validate: (val)=>val.length < MAXIMUM_INPUT_LENGTH
};
export const LASTNAME_INPUT = {
    id: "last_name",
    type: inputTypes.TEXT,
    title: "forms.fieldTitle.lastName",
    validate: (val)=>val.length < MAXIMUM_INPUT_LENGTH
};
export const EMAIL_INPUT = {
    id: "email",
    type: inputTypes.EMAIL,
    title: "forms.fieldTitle.workEmail",
    placeholder: "name@company.com",
    validate: (val)=>EMAIL_PATTERN.test(val),
    errorMessage: "forms.emailField.errorMessage"
};
export const PHONE_INPUT = {
    id: "phone",
    type: inputTypes.PHONE,
    title: "forms.fieldTitle.phoneNumber",
    validate: (val)=>(val === null || val === void 0 ? void 0 : val.length) >= MINIMUM_PHONE_LENGTH && (val === null || val === void 0 ? void 0 : val.length) < MAXIMUM_PHONE_LENGTH,
    errorMessage: "forms.phoneField.errorMessage"
};
export const COMPANY_SIZE_INPUT = {
    id: "company_size",
    type: inputTypes.SELECT,
    title: "forms.fieldTitle.companySize",
    options: companySizeList,
    defaultValue: "forms.fields.selectPlaceholder"
};
export const CHECKBOX_INPUT = {
    id: "checkbox_input",
    type: inputTypes.CHECKBOX,
    text: "forms.zendesk.tickets.contactConsentCheckbox",
    defaultValue: false
};
