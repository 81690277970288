import { REMOTE_WORK_FEATURES_SECTION, ADVANCED, SECURITY, ADVANCED_REPORTING, SUPPORT, ADMINISTRATION_AND_CONTROL, SECURITY_NEW, SUPPORT_NEW, CUSTOMER_FAVORITES, COLLABORATION, PRODUCTIVITY, VIEWS_AND_REPORTING, SECURITY_AND_PRIVACY, ENTERPRISE_REPORTING_AND_ANALYTICS } from "./default_sections";
import { COMMUNICATION_ESSENTIALS, COLLABORATION_AND_SHARING_ESSENTIALS, INSIGHTS_ESSENTIALS, MARKETING_OTHER_ESSENTIALS, MARKETING_ADVANCED_SECTION } from "./clusters/marketing";
import { getFreeTierEssentials } from "./free_tier_essentials";
import { DEFAULT_ESSENTIALS } from "./default_essentials";
const ESSENTIALS = ({ abTests, cookies, hasFreeTier })=>{
    if (hasFreeTier) {
        return getFreeTierEssentials(abTests, cookies);
    }
    return DEFAULT_ESSENTIALS(cookies, abTests);
};
const ALL_SECTIONS = {
    ESSENTIALS,
    REMOTE_WORK_FEATURES_SECTION,
    ADVANCED,
    SECURITY,
    ADVANCED_REPORTING,
    SUPPORT,
    COMMUNICATION_ESSENTIALS,
    ADMINISTRATION_AND_CONTROL,
    SECURITY_NEW,
    SUPPORT_NEW,
    COLLABORATION_AND_SHARING_ESSENTIALS,
    INSIGHTS_ESSENTIALS,
    MARKETING_OTHER_ESSENTIALS,
    MARKETING_ADVANCED_SECTION,
    CUSTOMER_FAVORITES,
    COLLABORATION,
    PRODUCTIVITY,
    VIEWS_AND_REPORTING,
    SECURITY_AND_PRIVACY,
    ENTERPRISE_REPORTING_AND_ANALYTICS
};
export default ALL_SECTIONS;
