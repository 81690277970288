import { TIERS } from "../../../pricing";
export const GENERIC_ALL_TRUE = {
    [TIERS.FREE]: {
        value: true
    },
    [TIERS.BASIC]: {
        value: true
    },
    [TIERS.STANDARD]: {
        value: true
    },
    [TIERS.PRO]: {
        value: true
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
export const ENTERPRISE_ONLY = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: false
    },
    [TIERS.PRO]: {
        value: false
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
export const WHITEBOARD = GENERIC_ALL_TRUE;
export const INTEGRATIONS = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: "pricingPage.features.plans.integrations_standard"
    },
    [TIERS.PRO]: {
        value: "pricingPage.features.plans.integrations_pro"
    },
    [TIERS.ENTERPRISE]: {
        value: "pricingPage.features.plans.integrations_enterprise"
    }
};
export const FORMS = GENERIC_ALL_TRUE;
export const GUESTS = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: "pricingPage.features.plans.guests_four_one"
    },
    [TIERS.PRO]: {
        value: "pricingPage.features.plans.unlimited"
    },
    [TIERS.ENTERPRISE]: {
        value: "pricingPage.features.plans.unlimited"
    }
};
export const DASHBOARDS = {
    [TIERS.FREE]: {
        value: "pricingPage.features.plans.dashboards_basic"
    },
    [TIERS.BASIC]: {
        value: "pricingPage.features.plans.dashboards_basic"
    },
    [TIERS.STANDARD]: {
        value: "pricingPage.features.plans.dashboards_standard"
    },
    [TIERS.PRO]: {
        value: "pricingPage.features.plans.dashboards_pro"
    },
    [TIERS.ENTERPRISE]: {
        value: "pricingPage.features.plans.dashboards_enterprise"
    }
};
export const CALENDAR = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: true
    },
    [TIERS.PRO]: {
        value: true
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
export const SCHEDULED_DASHBOARD_DELIVVERY = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: false
    },
    [TIERS.PRO]: {
        value: false
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
export const BOARD_ADMINISTRATORS = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: false
    },
    [TIERS.PRO]: {
        value: true
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
export const PANIC_MODE = {
    [TIERS.FREE]: {
        value: false
    },
    [TIERS.BASIC]: {
        value: false
    },
    [TIERS.STANDARD]: {
        value: false
    },
    [TIERS.PRO]: {
        value: false
    },
    [TIERS.ENTERPRISE]: {
        value: true
    }
};
