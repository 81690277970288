import * as FEATURES from "../features/features";
export const DEFAULT_ESSENTIALS = (cookies, abTests)=>{
    return {
        sectionName: "pricingPage.features.sections.essentials",
        features: [
            FEATURES.UNLIMITED_VIEWERS,
            FEATURES.UNLIMITED_BOARDS,
            FEATURES.UNLIMITED_DOCS,
            FEATURES.COLUMN_TYPES({
                cookies
            }),
            FEATURES.MOBILE,
            FEATURES.STORAGE({
                cookies,
                abTests
            }),
            FEATURES.ACTIVITY_LOG
        ]
    };
};
