import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
export const StyledSignupAndYoutubeComboButton = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  .secondary-button-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    flex-direction: column;
    align-items: center;
  }
`;
