function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { TIERS } from "constants/pricing";
const DEFAULT_TIER_DESCRIPTIONS = {
    [TIERS.FREE]: "pricingPage.tierDescriptions.individual.new",
    [TIERS.BASIC]: "pricingPage.tierDescriptions.basic.new",
    [TIERS.STANDARD]: "pricingPage.tierDescriptions.standard.new",
    [TIERS.PRO]: "pricingPage.tierDescriptions.pro.new",
    [TIERS.ENTERPRISE]: "pricingPage.tierDescriptions.enterprise.new"
};
export const getDefaultTierDescriptions = ({ hasStudentPlan })=>{
    const tierDescriptions = _object_spread({}, DEFAULT_TIER_DESCRIPTIONS);
    if (hasStudentPlan) {
        tierDescriptions[TIERS.FREE] = "pricingPage.tierDescriptions.student";
    }
    return tierDescriptions;
};
