import styled from "@emotion/styled";
export const StyledCloseButton = styled.div`
  position: absolute;
  top: -24px;
  right: -24px;
  font-size: 2.25rem;
  font-weight: 400;
  opacity: 0.7;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  outline: none;
  color: white;
  display: flex;

  &:hover {
    opacity: 1;
  }
`;
