import PricingVersions from "/constants/pricing-versions";
import BucketsPricingModelService from "./buckets-pricing-model-service";
import { getCurrencyName } from "services/pricing-service";
export const getPricingModelService = (pricingVersion)=>{
    const pricingModelsModelsMapping = {
        [PricingVersions.VERSION_9]: BucketsPricingModelService,
        [PricingVersions.VERSION_13]: BucketsPricingModelService
    };
    const PricingModelService = pricingModelsModelsMapping[pricingVersion] || pricingModelsModelsMapping[PricingVersions.DEFAULT_VERSION];
    return new PricingModelService();
};
export const getPricingVersion = ({ forcePricing } = {})=>{
    if (forcePricing) {
        const forcePricingVersionNumberArray = forcePricing.match(/\d+(?:\.\d+)?/g);
        if (forcePricingVersionNumberArray.length > 0) {
            const forcePricingVersionNumber = forcePricingVersionNumberArray[0];
            if (PricingVersions.ALLOWED_VERSIONS.includes(`${forcePricingVersionNumber}`)) {
                return forcePricing;
            }
        }
    }
    return PricingVersions.DEFAULT_VERSION;
};
export const getPricingVersionForSales = ({ forcePricing, cookies, abTests, forceCurrency, countryCode })=>{
    const currency = getCurrencyName({
        forceCurrency,
        countryCode
    });
    return getPricingVersion({
        forcePricing,
        cookies,
        abTests,
        currency
    });
};
