import styled from "@emotion/styled";
import colors from "/styles/colors";
export const StyledModalFooterComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.9);

  .modal-footer-text {
    color: ${colors["white"]};
    outline: none;
    font-size: 1.125rem;
  }

  .modal-footer-signup-wrapper {
    width: 55%;
    margin-left: 16px;
  }
`;
