import styled from "@emotion/styled";
import colors from "/styles/colors";
export const StyledModalWithSignupContentComponent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 18px;

  .modal-footer-wrapper {
    background-color: ${colors["brand-dark-blue"]};
    padding: 8px 0;
    width: 100%;
  }
`;
