import * as FEATURES from "../../features/features";
import { ADVANCED_REPORTING, SECURITY, SUPPORT } from "../default_sections";
export const COMMUNICATION_ESSENTIALS = ({ cookies, abTests })=>{
    return {
        sectionName: "pricingPage.features.sections.communication_essentials",
        features: [
            FEATURES.COMMUNICATION_WITH_CONTEXT,
            FEATURES.COLLABORATIVE_WHITEBOARD,
            FEATURES.INTEGRATIONS_WITH_GMAIL_AND_OUTLOOK,
            FEATURES.STORAGE({
                cookies,
                abTests
            })
        ]
    };
};
export const COLLABORATION_AND_SHARING_ESSENTIALS = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.collaboration_and_sharing_essentials",
        features: [
            FEATURES.EMBED_DOCUMENTS_AND_BRIEFS,
            FEATURES.MARKETING_REQUESTS_WITH_FORMS,
            FEATURES.READY_MADE_MARKETING_TEMPLATES,
            FEATURES.DEADLINE_MANAGEMENT,
            FEATURES.SHARE_BOARD_WITH_EXTERNAL_GUESTS,
            FEATURES.UNLIMITED_VIEWERS,
            FEATURES.AUTOMATIONS,
            FEATURES.MARKETING_INTEGRATIONS,
            FEATURES.TIMELINE({
                hasStudentPlan
            })
        ]
    };
};
export const INSIGHTS_ESSENTIALS = {
    sectionName: "pricingPage.features.sections.insights_essentials",
    features: [
        FEATURES.MARKETING_DASHBOARDS,
        FEATURES.MARKETING_CALENDAR,
        FEATURES.DAILY_REPORTS
    ]
};
export const MARKETING_OTHER_ESSENTIALS = {
    sectionName: "pricingPage.features.sections.other_essentials",
    features: [
        FEATURES.IMPORT_EXPORT_EXCEL,
        FEATURES.UNLIMITED_BOARDS,
        FEATURES.ADVANCED_COLUMN_TYPES,
        FEATURES.MOBILE,
        FEATURES.ACTIVITY_LOG,
        FEATURES.ZOOM_INTEGRATION
    ]
};
export const MARKETING_ADVANCED_SECTION = ({ hasStudentPlan })=>{
    return {
        sectionName: "pricingPage.features.sections.advanced",
        features: [
            FEATURES.KANBAN_VIEW,
            FEATURES.MAP,
            FEATURES.SEARCH,
            FEATURES.FORMS_CUSTOMIZATION({
                hasStudentPlan
            }),
            FEATURES.TIME_TRACKING,
            FEATURES.CHART_VIEW({
                hasStudentPlan
            }),
            FEATURES.FORMULA({
                hasStudentPlan
            }),
            FEATURES.TAGS,
            FEATURES.PRIVATE_BOARDS
        ]
    };
};
