function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { HUBSPOT_API_FIRSTNAME, HUBSPOT_API_LASTNAME, HUBSPOT_API_EMAIL, HUBSPOT_API_JOB, HUBSPOT_API_PHONE, HUBSPOT_API_COMPANY, HUBSPOT_API_COMPANY_SIZE, HUBSPOT_API_HOW_CAN_HELP, HUBSPOT_API_PRODUCT_QUESTION, productQuestionOptionsList } from "./hubspot-constants";
import { getUrlParamValueFromCurrentLocation } from "utils/url";
import { inputTypes, MAXIMUM_LONG_INPUT_LENGTH, FIRSTNAME_INPUT, LASTNAME_INPUT, PHONE_INPUT, EMAIL_INPUT } from "client/services/form-service/form-inputs";
import { SMALLEST_COMPANY_SIZE } from "client/services/form-service/form-constants";
import { companySizeValuesList } from "client/services/form-service/form-constants";
const validateHowCanWeHelpFieldRequiredByCompanySize = (currentValue, allInputsValues)=>{
    var _allInputsValues_HUBSPOT_API_COMPANY_SIZE;
    const companySize = (_allInputsValues_HUBSPOT_API_COMPANY_SIZE = allInputsValues[HUBSPOT_API_COMPANY_SIZE]) === null || _allInputsValues_HUBSPOT_API_COMPANY_SIZE === void 0 ? void 0 : _allInputsValues_HUBSPOT_API_COMPANY_SIZE.value;
    if (companySize === SMALLEST_COMPANY_SIZE) {
        return !!currentValue; //this can't be empty because CS need this value
    }
    return true;
};
const getEmail = ()=>{
    const emailParam = getUrlParamValueFromCurrentLocation("email");
    const emailString = emailParam.replace("%40", "@").replace(" ", "+");
    return emailString;
};
export const HUBSPOT_FIRSTNAME_INPUT = _object_spread_props(_object_spread({}, FIRSTNAME_INPUT), {
    inputName: HUBSPOT_API_FIRSTNAME,
    isRequired: true
});
export const HUBSPOT_FIRSTNAME_INPUT_WITH_PLACEHOLDER = _object_spread_props(_object_spread({}, FIRSTNAME_INPUT), {
    inputName: HUBSPOT_API_FIRSTNAME,
    isRequired: true,
    placeholder: "forms.fieldTitle.requiredFirstName"
});
export const HUBSPOT_LASTNAME_INPUT = _object_spread_props(_object_spread({}, LASTNAME_INPUT), {
    inputName: HUBSPOT_API_LASTNAME,
    isRequired: true
});
export const HUBSPOT_LASTNAME_INPUT_WITH_PLACEHOLDER = _object_spread_props(_object_spread({}, LASTNAME_INPUT), {
    inputName: HUBSPOT_API_LASTNAME,
    isRequired: true,
    placeholder: "forms.fieldTitle.requiredLastName"
});
export const HUBSPOT_EMAIL_INPUT = _object_spread_props(_object_spread({}, EMAIL_INPUT), {
    inputName: HUBSPOT_API_EMAIL,
    defaultValue: getEmail(),
    isRequired: true
});
export const HUBSPOT_EMAIL_INPUT_WITH_PLACEHOLDER = _object_spread_props(_object_spread({}, EMAIL_INPUT), {
    inputName: HUBSPOT_API_EMAIL,
    defaultValue: getEmail(),
    isRequired: true,
    placeholder: "forms.fieldTitle.requiredWorkEmail"
});
export const HUBSPOT_JOBTITLE_INPUT = {
    id: "job_title",
    inputName: HUBSPOT_API_JOB,
    type: inputTypes.TEXT,
    isRequired: false,
    title: "contactSales.hubspotForm.form.fieldTitle.jobTitle",
    validate: (val)=>!val || val.length < MAXIMUM_LONG_INPUT_LENGTH,
    errorMessage: "contactSales.hubspotForm.form.fieldErrorMsg.jobTitle"
};
export const HUBSPOT_JOBTITLE_INPUT_WITH_PLACEHOLDER = {
    id: "job_title",
    inputName: HUBSPOT_API_JOB,
    type: inputTypes.TEXT,
    isRequired: false,
    title: "contactSales.hubspotForm.form.fieldTitle.jobTitle",
    validate: (val)=>!val || val.length < MAXIMUM_LONG_INPUT_LENGTH,
    errorMessage: "contactSales.hubspotForm.form.fieldErrorMsg.jobTitle",
    placeholder: "contactSales.hubspotForm.form.fieldTitle.jobTitle"
};
export const HUBSPOT_PHONE_INPUT = _object_spread_props(_object_spread({}, PHONE_INPUT), {
    inputName: HUBSPOT_API_PHONE,
    isRequired: true
});
export const HUBSPOT_COMPANY_INPUT_WITH_PLACEHOLDER = {
    id: "company_name",
    inputName: HUBSPOT_API_COMPANY,
    type: inputTypes.TEXT,
    isRequired: true,
    placeholder: "contactSales.hubspotForm.form.fieldTitle.requiredCompanyName"
};
export const HUBSPOT_COMPANY_INPUT = {
    id: "company_name",
    inputName: HUBSPOT_API_COMPANY,
    type: inputTypes.TEXT,
    isRequired: true,
    title: "contactSales.hubspotForm.form.fieldTitle.companyName"
};
export const HUBSPOT_COMPANYSIZE_INPUT_WITH_PLACEHOLDER = {
    id: "company_size",
    inputName: HUBSPOT_API_COMPANY_SIZE,
    type: inputTypes.MULTI_SELECT,
    isMulti: false,
    options: companySizeValuesList,
    placeholder: "forms.fieldTitle.RequiredCompanySize",
    isRequired: true,
    closeMenuOnSelect: true
};
export const HUBSPOT_COMPANYSIZE_INPUT = {
    id: "company_size",
    inputName: HUBSPOT_API_COMPANY_SIZE,
    type: inputTypes.MULTI_SELECT,
    isMulti: false,
    options: companySizeValuesList,
    title: "forms.fieldTitle.companySize",
    isRequired: true,
    closeMenuOnSelect: true
};
export const getHubspotHowCanHelpInput = (overrideParams = {})=>{
    return {
        id: "how_can_help",
        inputName: HUBSPOT_API_HOW_CAN_HELP,
        type: inputTypes.TEXT_AREA,
        isRequired: false,
        placeholder: (overrideParams === null || overrideParams === void 0 ? void 0 : overrideParams.placeholderKey) || "contactSales.hubspotForm.form.fieldPlaceholder.howCanHelp",
        title: (overrideParams === null || overrideParams === void 0 ? void 0 : overrideParams.titleKey) || "contactSales.hubspotForm.form.fieldTitle.howCanHelp",
        validateOnFormSubmission: validateHowCanWeHelpFieldRequiredByCompanySize,
        errorMessage: "forms.detailsField.requiredSoWeCanHelpError"
    };
};
export const HUBSPOT_HOWPLANUSE_INPUT = {
    id: "how_plan_use",
    inputName: HUBSPOT_API_HOW_CAN_HELP,
    type: inputTypes.TEXT_AREA,
    isRequired: false,
    placeholder: "contactSales.hubspotForm.form.fieldPlaceholder.howCanHelp",
    title: "contactSales.hubspotForm.form.fieldTitle.howPlanToUse",
    validateOnFormSubmission: validateHowCanWeHelpFieldRequiredByCompanySize,
    errorMessage: "forms.detailsField.requiredSoWeCanHelpError"
};
export const HUBSPOT_PRODUCT_QUESTION_INPUT = {
    id: "product_question",
    inputName: HUBSPOT_API_PRODUCT_QUESTION,
    type: inputTypes.MULTI_SELECT,
    isMulti: true,
    title: "contactSales.hubspotForm.form.fieldTitle.productQuestion",
    options: productQuestionOptionsList,
    placeholder: "forms.fields.selectPlaceholder",
    isRequired: true,
    errorMessage: "forms.requiredError"
};
export const HUBSPOT_PRODUCT_QUESTION_INPUT_WITH_PLACEHOLDER = {
    id: "product_question",
    inputName: HUBSPOT_API_PRODUCT_QUESTION,
    type: inputTypes.MULTI_SELECT,
    isMulti: true,
    options: productQuestionOptionsList,
    placeholder: "contactSales.hubspotForm.form.fieldTitle.shorterRequiredProductQuestion",
    isRequired: true,
    errorMessage: "forms.requiredError"
};
